import React from "react"
import PostCardBackground from "../../../images/postCard.png"
import Telegram from "../../../images/telegramFooter.svg"
import { Fade } from "react-awesome-reveal"
import { Box, Image, Text, Link, Heading } from "@chakra-ui/core"
import Button from "../Button"

const PostCardSection = () => {
  return (
    <Fade triggerOnce={true} duration={500}>
      <Box
        py={5}
        px={3}
        mx="auto"
        position="relative"
        width="95vw"
        maxW="780px"
      >
        <Image
          src={PostCardBackground}
          top={0}
          left={"5%"}
          width="100%"
          height="100%"
          position="absolute"
          zIndex={-1}
        />
        <Box px={2} pt={16} pb={10}>
          <Heading
            fontSize={["xl", null, "3xl"]}
            textAlign="center"
            fontWeight="bold"
            mb={5}
          >
            டெலிக்ராமில் இணையுங்கள்
          </Heading>
          <Text
            color="#575757B7"
            mx="auto"
            pt={5}
            textAlign="center"
            fontWeight="bold"
            maxW="500px"
          >
            இக்குழுவில் இணைந்து ஆட்சித் தமிழின் அனைத்து தகவல்களையும் துரிதமாகப்
            பெற்று போட்டித் தேர்வுகளில் உங்கள் வெற்றியை உறுதி செய்துகொள்ளுங்கள்
          </Text>
          <Box textAlign="center" px={5} mt={8}>
            <Link
              href={"https://t.me/aatchithamizh"}
              target="_blank"
              _hover={{ textDecoration: "none" }}
            >
              <Button
                py={4}
                bg="#039be6"
                color="#fff"
                borderRadius={0}
                type="submit"
                fontSize="sm"
              >
                டெலெக்ராமில் இணையுங்கள்
                <Image ml={2} w="1.2rem" src={Telegram} />
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Fade>
  )
}
export default PostCardSection
