import React from "react"
import { Box, Text, Image } from "@chakra-ui/core"
import quoteIcon from "../../../images/quote.svg"

const QuoteSection = () => {
  return (
    <Box my={10} position="relative">
      <Image maxH="30px" src={quoteIcon} alt="quote" />
      <Text
        mt={2}
        ml={[0, 3, 8]}
        fontSize={["md", "lg"]}
        fontWeight="bold"
        color="#000000A2"
        whiteSpace="nowrap"
      >
        தொட்டனைத்து ஊறும் மணற்கேணி மாந்தர்க்குக் <br /> கற்றனைத்து ஊறும் அறிவு.
        - (396)
      </Text>
    </Box>
  )
}
export default QuoteSection
