import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import PostCardSection from "../components/Home/PostCardSection"
import SectionHeader from "../components/SectionHeader"
import { Box, Grid, Text } from "@chakra-ui/core"
import SectionTitle from "../components/SectionTitle"
import QuoteSection from "../components/Kannotam/QuoteSection"
import PageBanner from "../components/PageBanner"
import Img from "gatsby-image"

import { useStaticQuery, graphql } from "gatsby"

const About = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      stage: file(relativePath: { eq: "StageImage.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      natrajIps: file(relativePath: { eq: "NatrajIps.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Box>
      <SEO title="About" />
      <Layout>
        <PageBanner bannerText="ஆட்சித்தமிழுக்கு வாருங்கள் அரசு பணியாளராய் மாறுங்கள்" />
        <SectionHeader color="#0A0A0A" title="கண்ணோட்டம்" />
        <Box>
          <Box pt={[2, 5, 10]}>
            <SectionTitle title="திரு. ஆர்.நட்ராஜ் ஐ.பி. எஸ்" />
            <Grid
              px={[3, 8, 10]}
              gap={[3, 6, 12]}
              mt={8}
              mb={10}
              templateColumns={["1fr", null, "2fr 3fr"]}
              maxW="1400px"
              mx="auto"
              alignItems="start"
            >
              <Box m={[1, 3, 10]}>
                <Img
                  fluid={data.natrajIps.childImageSharp.fluid}
                  alt="Nataraj IPS"
                />
              </Box>
              <Box>
                <Text fontSize="lg" lineHeight={1.9}>
                  1960 ஆம் ஆண்டு வானூர்தி அபிவிருத்தி அமைத்தல் பிரிவில் (DRDO)
                  விஞ்ஞானியாக தன்னுடைய ஆராய்ச்சி வாழ்க்கையைத் தொடங்கிய அப்துல்
                  கலாம், ஒரு சிறிய ஹெலிகாப்டரை இந்திய ராணுவத்திற்காக வடிவமைத்து
                  கொடுத்தார். பின்னர், இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில்
                  (ISRO) தனது ஆராய்ச்சிப்பணிகளைத் தொடர்ந்த அவர், துணைக்கோள்
                  ஏவுகணைக் குழுவில் (SLV) செயற்கைக்கோள் ஏவுதலில் முக்கிய
                  பங்காற்றினார். 1980 ஆம் ஆண்டு SLV -III ராக்கெட்டைப்
                  பயன்படுத்தி ரோகினி-I என்ற துணைக்கோளை வெற்றிகரமாக விண்ணில்
                  ஏவச்செய்தார். இது அவருக்கு மட்டுமல்லாமல், இந்தியாவிற்கே ஒரு
                  சாதனையாக அமைந்தது. இத்தகைய வியக்கதக்க செயலைப் பாராட்டி மத்திய
                  அரசு இவருக்கு 1981 ஆம் ஆண்டு இந்தியாவின் மிகப் பெரிய விருதான
                  “பத்ம பூஷன்” விருது வழங்கி கௌரவித்தது.1963 ஆம் ஆண்டு முதல்
                  1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல
                  பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆண்டு “பொக்ரான் அணு ஆயுத
                  சோதனையில்” முக்கிய பங்காற்றியுள்ளார். இந்தியாவை அணு ஆயுத
                  வல்லரசாக மாற்றிய ஏ.பி.ஜே அப்துல் கலாம், இதுவரை ஐந்து ஏவுகணை
                  திட்டங்களில் பணிபுரிந்துள்ளார். அவர், அனைவராலும் இந்திய ராணுவ
                  ராக்கெட் படைப்பின் பிதாவாக போற்றப்படுகிறார்.
                </Text>
                <QuoteSection />
              </Box>
            </Grid>
          </Box>

          <Box bg="#FFF0F07E" pt={16}>
            <SectionTitle title="திரு. ஆர்.நட்ராஜ் ஐ.பி. எஸ்" />
            <Grid
              px={[3, 8, 10]}
              gap={[3, 6, 12]}
              mt={8}
              mb={10}
              templateColumns={["1fr", null, "2fr 3fr"]}
              maxW="1400px"
              mx="auto"
              alignItems="start"
            >
              <Box m={[1, 3, 10]}>
                <Img
                  fluid={data.stage.childImageSharp.fluid}
                  alt="Nataraj IPS"
                />
              </Box>
              <Box>
                <Text fontSize="lg" lineHeight={1.9}>
                  1960 ஆம் ஆண்டு வானூர்தி அபிவிருத்தி அமைத்தல் பிரிவில் (DRDO)
                  விஞ்ஞானியாக தன்னுடைய ஆராய்ச்சி வாழ்க்கையைத் தொடங்கிய அப்துல்
                  கலாம், ஒரு சிறிய ஹெலிகாப்டரை இந்திய ராணுவத்திற்காக வடிவமைத்து
                  கொடுத்தார். பின்னர், இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில்
                  (ISRO) தனது ஆராய்ச்சிப்பணிகளைத் தொடர்ந்த அவர், துணைக்கோள்
                  ஏவுகணைக் குழுவில் (SLV) செயற்கைக்கோள் ஏவுதலில் முக்கிய
                  பங்காற்றினார். 1980 ஆம் ஆண்டு SLV -III ராக்கெட்டைப்
                  பயன்படுத்தி ரோகினி-I என்ற துணைக்கோளை வெற்றிகரமாக விண்ணில்
                  ஏவச்செய்தார். இது அவருக்கு மட்டுமல்லாமல், இந்தியாவிற்கே ஒரு
                  சாதனையாக அமைந்தது. இத்தகைய வியக்கதக்க செயலைப் பாராட்டி மத்திய
                  அரசு இவருக்கு 1981 ஆம் ஆண்டு இந்தியாவின் மிகப் பெரிய விருதான
                  “பத்ம பூஷன்” விருது வழங்கி கௌரவித்தது.1963 ஆம் ஆண்டு முதல்
                  1983 ஆம் ஆண்டு வரை, இந்திய விண்வெளி ஆராய்ச்சி கூடத்தில் பல
                  பணிகளை சிறப்பாக செய்த இவர், 1999 ஆம் ஆண்டு “பொக்ரான் அணு ஆயுத
                  சோதனையில்” முக்கிய பங்காற்றியுள்ளார்.
                </Text>
                <QuoteSection />
              </Box>
            </Grid>
          </Box>

          <PostCardSection />
        </Box>
      </Layout>
    </Box>
  )
}

export default About
