import React from "react"
import { Flex, Text, Divider } from "@chakra-ui/core"
import { Fade } from "react-awesome-reveal"

interface SectionTitleProps {
  title: string
}
const HorizontalLine = () => {
  return (
    <Divider
      opacity={1}
      w="30px"
      borderColor="#EB1D27"
      borderWidth="7px"
      borderRadius="3em"
      display={["none", null, "block"]}
    />
  )
}

const SectionTitle = (props: SectionTitleProps) => {
  return (
    <Fade triggerOnce duration={500} direction="down">
      <Flex mb={3} justify="center" align="center">
        <HorizontalLine />
        <Text
          color="#000239"
          px={2}
          fontWeight="bold"
          fontSize={["3xl", null, "5xl"]}
        >
          {props.title}
        </Text>
        <HorizontalLine />
      </Flex>
    </Fade>
  )
}
export default SectionTitle
