import React from "react"
import { Box, Text } from "@chakra-ui/core"
import { Fade } from "react-awesome-reveal"

interface SectionHeaderProps {
  title: string
  color?: string
}

const SectionHeader = (props: SectionHeaderProps) => {
  return (
    <Fade triggerOnce duration={500} direction="down">
      <Box my={10} position="relative">
        <Text
          fontWeight="bold"
          textAlign="center"
          fontFamily="Mukta Malar"
          color={props.color ?? "#2E3292"}
          fontSize={["3xl", null, "5xl"]}
        >
          {props.title}
        </Text>
        <Text
          position="absolute"
          bottom="-25%"
          left="50%"
          transform={[
            "translateX(-50%) scale(1.1)",
            null,
            "translateX(-50%) scale(1.6)",
          ]}
          transformOrigin="50% 100%"
          fontSize="5xl"
          color="#2E3292"
          opacity={0.05}
          textAlign="center"
          fontWeight="bold"
        >
          {props.title}
        </Text>
      </Box>
    </Fade>
  )
}
export default SectionHeader
